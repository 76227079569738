<template>
  <div
    v-if="$parent.checkActive(2)"
    class="notifyWizardStepTwo mb-3"
  >
    <!-- LOADER WRAPPER (displayed when message has been sent until response is recieved) -->
    <!-- STATUS SUCCESS -->
    <div
      v-if="$parent.sendingWasSuccessful == true"
      class="loaderWrapper"
    >
      <p class="lead m--font-success">
        Message was successfully sent.
      </p>
    </div>
    <!-- STATUS Error -->
    <div
      v-else-if="$parent.sendingWasSuccessful == false"
      class="loaderWrapper"
    >
      <p class="lead m--font-danger">
        Message was not sent.
      </p>
    </div>
    <!-- STATUS NORMAL -->
    <div
      v-else
      class="loaderWrapper"
    >
      <div class="m-loader m-loader--info" />
      <p class="lead m--font-info">
        Sending the message to the recipient,<br>please wait a moment.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotifyWizardStepTwo'
}
</script>

<style scoped>
.form-control.btn.btn-secondary {
  text-align: left;
}
.loaderWrapper {
  margin: auto;
  text-align: center;
}
.m-loader {
  width: 100%;
  display: inline-block;
}
.m-loader::before {
  width: 5rem;
  height: 5rem;
  margin-left: -2.5rem;
}
.lead.m--font-info {
  padding-top: 5rem;
}
</style>
